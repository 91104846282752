<template>
  <div :class="{'js-entry': true, 'o-entry': true, 'c-image-entry': true, 'c-image-entry--map-mode': mode === 'map', 'scale-to-screenheight': this.ScaleToScreenHeight}">
    <div class="c-image-entry__view">
      <xp-img
      :src="imageUrl"
      :width="imageWidth"
      :height="imageHeight"
      :hotspots="entryData.Json.ImageMap || []"
      :alt="entryData.Image.Alt"
      @hotspot-click="onAreaClick"
      :placeholderSrc="entryData.Image.Thumb"/>
    </div>
    <div :class="{'c-image-entry__btns': true, 'is-hidden': $xp.navigation.isNavHidden}">
      <button aria-hidden="true"
        :class="{ 'c-image-entry__zoom-btn': true, 'js-zoom-dialog': true }"
        v-if="entryData.Json.Link2FullSize"
        @click="onZoomDialogClick">
        <span class="xp xp-zoom-in xp--large" aria-hidden="true"></span>
      </button>
      <button
        :class="{ 'c-image-entry__caption-btn': true, 'js-caption': true }"
        v-if="entryData.Json.Caption && entryData.Json.Caption.length > 0"
        @click="onCaptionClick" :aria-label="$t(isCaptionActive ? 'global.srHideImageInfo': 'global.srShowImageInfo')">
        <span class="xp xp-information xp--large" aria-hidden="true"></span>
      </button>
    </div>
    <div
      :class="{ 'c-image-entry__caption': true, 'js-caption': true, 'c-image-entry__caption--overlay': true, 'c-image-entry__caption--overlay--active': isCaptionActive }"
      v-if="entryData.Json.Caption && entryData.Json.Caption.length > 0"
      @click="onCaptionClick" :aria-label="$t(isCaptionActive ? 'global.srHideImageInfo': 'global.srShowImageInfo')">
      {{ entryData.Json.Caption }}
    </div>
    <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" mode="out-in" :duration="200" v-if="entryData.Json.Link2FullSize">
      <div :class="{ 'c-image-entry__zoom-dialog': true }" v-if="isZoomDialogActive">
        <panzoom-map :height="entryData.Image.Height" :width="entryData.Image.Width" :start-center="{ x: entryData.Image.Width / 2, y: entryData.Image.Height / 2 }">
          <xp-img
          :src="zoomImageUrl"
          @mousedown.prevent=""
          :hotspots="[]"
          :placeholderSrc="entryData.Image.Thumb"/>
        </panzoom-map>
        <button @click="onZoomDialogClick" class="c-image-entry__zoom-btn">
          <span class="xp xp-close xp--large" aria-hidden="true"></span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import entryMixin from '../../mixins/entry'
import PanzoomMap from '../PanzoomMap.vue'
import debug from 'debug'
import throttle from '../../util/throttle'
import XpImg from '../XpImg'
import { handleUrl } from '@/util/router-guards'
import EventHub from '../../util/EventHub'
const log = debug('xpedeo-core:ImageEntry')
debug.enable(process.env.VUE_APP_DEBUG)

export default {
  mixins: [entryMixin],
  components: { PanzoomMap, XpImg },
  props: {
    mode: {
      type: String,
      default: 'image',
      validator: (value) => {
        if (['map', 'image'].includes(value)) {
          return true
        } else {
          console.warn('ImageEntry: mode property can either be "map" or "image')
          return false
        }
      }
    }
  },
  computed: {
    imageUrl () {
      // eslint-disable-next-line no-unused-expressions
      this.shouldRecomputeImageVariables
      return this.$xp.content.getImageUrl(this.entryData.Image, this.mode === 'map' ? 0 : this.imageWidth)
    },
    zoomImageUrl () {
      // eslint-disable-next-line no-unused-expressions
      this.shouldRecomputeImageVariables
      return this.$xp.content.getImageUrl(this.entryData.Image, 0)
    },
    imageHeight () {
      // eslint-disable-next-line no-unused-expressions
      this.shouldRecomputeImageVariables
      return this.mode === 'map' ? Number(this.entryData.Image.Height) : Math.round(this.imageWidth / this.entryData.Image.Width * this.entryData.Image.Height)
    },
    imageWidth () {
      // eslint-disable-next-line no-unused-expressions
      this.shouldRecomputeImageVariables
      return this.mode === 'map' ? this.entryData.Image.Width : this.$xp.content.getRequiredImageWidth(this.entryData.Image)
    }
    //  this.imageWidth = this.$xp.content.getRequiredImageWidth(this.entryData.Image)
    //         this.imageHeight = Math.round(this.imageWidth / Number(this.entryData.Image.Width) * Number(this.entryData.Image.Height))
    //         this.imageUrl = this.$xp.content.getImageUrl(this.entryData.Image, this.imageWidth)
  },
  data (vm) {
    return {
      isCaptionActive: false,
      isZoomDialogActive: false,
      // imageWidth: vm.mode === 'map' ? Number(vm.entryData.Image.Width) : vm.$xp.content.getRequiredImageWidth(vm.entryData.Image),
      // imageHeight: vm.mode === 'map' ? Number(vm.entryData.Image.Height) : Math.round(vm.imageWidth / vm.entryData.Image.Width * vm.entryData.Image.Height),
      // imageUrl: null,
      // zoomImageUrl: null,
      // imageUrlData: null,
      ScaleToScreenHeight: this.entryData.ScaleToScreenHeight,
      shouldRecomputeImageVariables: 0 // this is a hack to force recompute of image variables see : https://logaretm.com/blog/forcing-recomputation-of-computed-properties/
    }
  },
  created () {
    // EventHub.Bus.$on(EventHub.TYPES.RE_INIT_IMAGE_MAP, this.initImageMap)
    log('created', this.ScaleToScreenHeight)
    this.onAreaClick = throttle(this.onAreaClick)
    // this.imageWidth = this.mode === 'map' ? this.entryData.Image.Width : this.$xp.content.getRequiredImageWidth(this.entryData.Image)
    // this.imageHeight = this.mode === 'map' ? Number(this.entryData.Image.Height) : Math.round(this.imageWidth / this.entryData.Image.Width * this.entryData.Image.Height)
    // this.imageUrl = this.$xp.content.getImageUrl(this.entryData.Image, this.mode === 'map' ? 0 : this.imageWidth)
    // this.zoomImageUrl = this.$xp.content.getImageUrl(this.entryData.Image, 0)
    // this.$xp.navigation.checkScreenReader().then(() => {
    if (this.$xp.navigation.isScreenReader) this.isCaptionActive = true
    // })
  },
  mounted () {
    if (this.entryData.Json.Href &&
     this.entryData.Json.Href.length > 0 && !this.entryData.Json.ImageMap.length) {
      this.$el.querySelector('.xp-img img').addEventListener('click', () => {
        handleUrl(this.entryData.Json.Href, this.$router)
      })
    }
  },
  methods: {
    onAreaClick (hotspot) {
      console.log('onAreaClick')
      if (this.pageData.Class === 'XpSplashScreen') {
        EventHub.Bus.$emit(EventHub.TYPES.CLICKED_HOTSPOT_SPLASHSCREEN)
      }
      if (hotspot.href.length > 0) {
        handleUrl(hotspot.href, this.$router)
      } else if (hotspot.route && hotspot.route.length > 0 && hotspot.route.startsWith('/page/')) {
        this.$router.push(hotspot.route)
      }
    },
    onCaptionClick () {
      this.isCaptionActive = !this.isCaptionActive
    },
    onZoomDialogClick () {
      if (!this.isZoomDialogActive) {
        this.$xp.navigation.hideNav()
        this.$xp.navigation.preventAutoHide()
        this.isZoomDialogActive = true
        this.$xp.media.setIsImageZoomDialogActive(true)
      } else {
        this.isZoomDialogActive = false
        this.$xp.media.setIsImageZoomDialogActive(false)
        setTimeout(() => {
          this.$xp.navigation.allowAutoHide()
          this.$xp.navigation.showNav(true)
        }, 200)
      }
    }
  },
  watch: {
    '$xp.screen.orientation': {
      async handler () {
        // await this.$nextTick()
        this.shouldRecomputeImageVariables++
        // if (this.mode !== 'map') {
        //   setTimeout(() => {
        //     this.imageWidth = this.$xp.content.getRequiredImageWidth(this.entryData.Image)
        //     this.imageHeight = Math.round(this.imageWidth / Number(this.entryData.Image.Width) * Number(this.entryData.Image.Height))
        //     this.imageUrl = this.$xp.content.getImageUrl(this.entryData.Image, this.imageWidth)
        //   }, 1000)
        // }
      }
    },
    '$xp.navigation.isScreenReader': {
      async handler () {
        if (this.$xp.navigation.isScreenReader) this.isCaptionActive = true
      }
    }
  }
}
</script>
